/**
 * @name navigateToCompareCardPage
 * @description navigates to compare card page
 */
function navigateToCompareCardPage() {
    window.location = ('/money/credit-cards/compare-cards/');
}
/**
 * @name checkStorage
 * @description checks if local storage is available and returns the bool. alerts user if local storage isnt supported
 * @returns {boolean} if local storage is available
 */
function checkStorage() {
    if (typeof (Storage) !== 'undefined') {
        // Code for localStorage/sessionStorage.
        return true;
    }
    console.log('Sorry! This browser doesn\'t support Web Storage.');
    return false;
}

/**
 * @name readStorage
 * @description reads taCardCompare key from localStorage to collect array of cards to compare
 * @returns {array} array of cards currently in storage
 */
function readStorage() {
    let storage = localStorage.getItem('taCardCompare');

    storage = JSON.parse(storage);

    if (!storage) storage = [];

    return storage;
}

/**
 * @name addToLocalStorage
 * @description adds cardSlug to local storage. if cardSlug already exist, it removes it from local storage. this method also alerts vue components of the storage update
 * @param cardSlug {string} slug to add to localStorage
 * @param cardImage {string} card image url associated with slug
 * @param navigateAwayBoolean {boolean} whether or not to navigate to card compare page after push
 */
function addToLocalStorage(cardSlug, cardImage, navigateAwayBoolean) {
    if (!checkStorage()) {
        return;
    }

    // read storage
    const storage = readStorage();

    // update storage
    if (storage && storage.length > 0) {
        const item = storage.find((card) => {
            return card.slug === cardSlug;
        });

        // Remove card if it's already there or add card if it's not there
        if (item) {
            // navigate away if its checked already and navigation is true
            if (navigateAwayBoolean === true) {
                navigateToCompareCardPage();
                return;
            }
            storage.splice(storage.indexOf(item), 1);
        } else {
            storage.push({ slug: cardSlug, image: cardImage });
        }
    } else {
        storage.push({ slug: cardSlug, image: cardImage });
    }

    // save storage
    localStorage.setItem('taCardCompare', JSON.stringify(storage));
    window.dispatchEvent(window.ta.ccLocalStorage.customEvent); // Dispatch our custom event so card compare footer can listen to it

    if (navigateAwayBoolean === true) navigateToCompareCardPage();
}

function setCheckboxState() {
    const checkboxes = document.getElementsByClassName('card-tile__checkbox-group');

    // read storage
    const storage = readStorage();

    if (storage && storage.length > 0) {
        // update storage
        for (const checkboxGroup of checkboxes) {
            const cardInput = checkboxGroup.getElementsByTagName('input')[0];
            const cardSlug = cardInput.id.split('compareInput-')[1];

            const item = storage.find((card) => {
                return card.slug === cardSlug;
            });

            cardInput.checked = !!item;
        }
    }
}

function CustomEvent(event, params) {
    const new_params = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, new_params.bubbles, new_params.cancelable, new_params.detail);
    return evt;
}
// Add Custom Event for IE 11 Support
(function () {
    if (typeof window.CustomEvent === 'function') return false;
    window.CustomEvent = CustomEvent;
    return true;
}());

function registerStorage() {
    // window.ta = window.ta || {};
    const customEvent = new CustomEvent('CC_CARD_MODIFIED');
    const ccLocalStorage = {
        checkStorage,
        readStorage,
        addToLocalStorage,
        customEvent,
        setCheckboxState,
    };

    window.ta = {
        ccLocalStorage,
    };
}

registerStorage();
